import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import ImageComponent from "./Imagecomponent";

function App() {
  return (
    <body>
      <header className="header">
      <div className="header-content">
        <div className="logo">Kyle Katzenmaier</div>
        <nav className="nav">
          
          <div className="social-icons">
            <a href="https://www.instagram.com/kyle_katz1026/" className="social-icon"><FontAwesomeIcon icon={faInstagram}/></a>
            <a href="https://www.linkedin.com/in/kylekatzenmaier/" className="social-icon"><FontAwesomeIcon icon={faLinkedin}/></a>
            <a href="https://github.com/BuffManKyle" className="social-icon"><FontAwesomeIcon icon={faGithub}/></a>
          </div>
        </nav>
      </div>
    </header>
    <div className="background-container">
      <div className="overlay-content items-center">
        <h1>Hello...</h1> 
        <p>
          I am Kyle, I enjoy creating websites and taking pictures. This is a temporary portfolio while I work on an improved one. Enjoy some cool pictures below while you wait.
        </p> 
        <p>
          Currently, I work in IT with dreams to enter a more creative career. This website only includes photography, the finished website will include web development and more.
        </p>
      </div>
    </div>
    <div>
      <ImageComponent/>
    </div>
    </body>
  );
}

export default App;
