import React from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css'

const ImageComponent = () => {
  const images = [
    
    {
        src: "/images/Policie.jpg",
        alt: "Description of image 2",
    },
    {
      src: "/images/OrangeBMW.jpg",
      alt: "Description of image 1",
    },
    {
        src: "/images/OrangeBMWd.jpg",
        alt: "Description of image 2",
    },
    {
        src: "/images/OrangeBMWe.jpg",
        alt: "Description of image 2",
    },
    {
        src: "/images/OrangeBMWf.jpg",
        alt: "Description of image 2",
    },
    {
      src: "/images/yellow.jpg",
      alt: "Description of image 2",
    },
    {
      src: "/images/69K20.jpg",
      alt: "Description of image 2",
    },
    {
      src: "/images/vw.jpg",
      alt: "Description of image 2",
    },
    {
        src: "/images/PorscheLED.jpg",
        alt: "Description of image 2",
    },
    {
        src: "/images/YellowPorsche.jpg",
        alt: "Description of image 2",
    },
    {
        src: "/images/RS.jpg",
        alt: "Description of image 2",
    },
    {
      src: "/images/LandscapeGeorgetown.jpg",
      alt: "Description of image 2",
   },
    {
        src: "/images/Prague.jpg",
        alt: "Description of image 2",
    },
    {
      src: "/images/PragueStreet.jpg",
      alt: "Description of image 2",
    },
    {
      src: "/images/ViennaFerrari.jpg",
      alt: "Description of image 2",
    },
    {
      src: "/images/ViennaStreet.jpg",
      alt: "Description of image 2",
    },
    {
      src: "/images/IcelandBoat.jpg",
      alt: "Description of image 2",
    },
    {
      src: "/images/Icelanddoor.jpg",
      alt: "Description of image 2",
    },
    {
      src: "/images/IcelandCrane.jpg",
      alt: "Description of image 2",
    },
    {
      src: "/images/Newyorksunrise.jpg",
      alt: "Description of image 2",
    },
    {
      src: "/images/BelvederePalace.jpg",
      alt: "Description of image 2",
    },
    {
      src: "/images/MountainFields.jpg",
      alt: "Description of image 2",
    },
    {
      src: "/images/SunsetBoat.jpg",
      alt: "Description of image 2",
    },
    {
      src: "/images/SunsetRocks.jpg",
      alt: "Description of image 2",
    },
    {
      src: "/images/cloudysunset.jpg",
      alt: "Description of image 2",
    },
    {
      src: "/images/SunriseGolden.jpg",
      alt: "Description of image 2",
    },
    {
      src: "/images/CannonIdahoFalls.jpg",
      alt: "Description of image 2",
    },
    {
      src: "/images/FlowersIdahoFalls.jpg",
      alt: "Description of image 2",
    },
    {
      src: "/images/flowers.jpg",
      alt: "Description of image 2",
    },
    {
      src: "/images/OrangeDayLily.jpg",
      alt: "Description of image 2",
    },
    
    
    
  ];

  return (
    <div className="gallery">
            {images.map((image, index) => (
            <Zoom key={index}>
            <img
            key={index}
            src={image.src}
            alt={image.alt}
            className='gallery-images'
            loading="lazy"
            />
            </Zoom>
        ))}
    </div>
  );
};

export default ImageComponent;
